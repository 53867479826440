import React from 'react';
import { graphql } from 'gatsby';
import WebTab from './_code-web';
import ReactNativeTab from './_code-reactnative';
import AndroidTab from './_code-android';
import AppleTab from './_code-apple';
import { PageHero, PageWithSubNav, PlatformTabs } from '../../../../components';
import pageHeroData from '../../../../data/pages/guidelines.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="code"
      title="Brand"
      designCode
      subnav="guidelines">
      <PageHero heroData={pageHeroData} tierTwo="Colors" tierThree="Brand" />

      <PlatformTabs
        pageType="guidelines"
        platforms={{
          web: <WebTab data={data} />,
          reactnative: <ReactNativeTab data={data} />,
          android: <AndroidTab data={data} />,
          apple: <AppleTab data={data} />,
        }}
      />
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query brandColorsCode($type: String = "/Brand/") {
    ...colorDataCode
  }
`;
